.App {
  text-align: center;
}
.pathInEdit {
  background-color: rgba(247,103,2, 0.1) !important;
}


.loadingPage {
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -99;
}

.spinner {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 4px solid black;
}



